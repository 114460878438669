<template>
  <div class="form-contact">
    <div class="content row">
      <div class="col-lg-10">
        <h3 v-if="typeof formSchema.title != 'undefined'">
          {{ formSchema.title }}
        </h3>
        <p class="main-p">
          {{ formSchema.description }}
        </p>
        <form @submit.prevent="sendData(formData)">
          <div class="contact-page-form">
            <div class="contact-input">
              <div
                class="contact-inner"
                v-for="item in formSchema.form.filter(
                  (item) => item.type == 'input'
                )"
                :key="item"
              >
                <span>{{ item.title }}</span>
                <input
                  :name="'con_' + item.name"
                  type="text"
                  :placeholder="item.placeholder"
                  v-model="formData[item.name]"
                  required
                />
              </div>
            </div>
            <div
              class="contact-inner contact-message"
              v-for="item in formSchema.form.filter(
                (item) => item.type == 'textarea'
              )"
              :key="item"
            >
              <span>{{ item.title }}</span>
              <textarea
                :name="'con_' + item.name"
                :placeholder="item.placeholder"
                v-model="formData[item.name]"
                required
              ></textarea>
            </div>
            <div class="contact-submit-btn d-flex justify-content-end">
              <div class="main-button">
                <button type="submit">
                  {{
                    formSchema.form.find((item) => item.type == "button").title
                  }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

export default {
  name: "FranchiseView",
  components: {
    LayoutLanding,
  },
  props: {
    formSchema: { type: Object, default: [{}] },
    buttonFunction: { type: Function, default: () => {} },
  },
  setup(props) {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    onMounted(() => {});
    const formData = ref({});
    for (var i = 0; i < props.formSchema.form.length; i++) {
      if (props.formSchema.form[i].type != "button") {
        formData.value[props.formSchema.form[i].name] = "";
      }
    }
    const sendData = async (data)=>
    {
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')
      data.token = token
      props.buttonFunction(data)
      formData.value = []
      for (var i = 0; i < props.formSchema.form.length; i++) {
        if (props.formSchema.form[i].type != "button") {
          formData.value[props.formSchema.form[i].name] = "";
        }
      }
    }
    return { formData, sendData };
  },
};
</script>
